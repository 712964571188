import { Variables } from './../utils/variables';
import { Storage } from '@ionic/storage';

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError, BehaviorSubject} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Platform} from '@ionic/angular';
import { resolve } from 'url';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    subjectLogout = new BehaviorSubject(false);

    constructor(
        private storage: Storage,
        private http: HttpClient
    ) {

    }

    isLoggedIn(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.storage.get('cookie').then(data => {
                if (data) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    logout(): void {
        this.storage.remove('cookie');
        this.subjectLogout.next(true);
    }

    login(login: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post<any>(Variables.url + '/auth/sign-in', null, { headers: {
                Authorization : 'Basic ' + btoa(login + ':' + password)
            }, observe: 'response' })
                .subscribe(
                    response => {
                        if (response.body.status === 'OK') {
                            this.storage.set('cookie', encodeURIComponent(response.body.data)).then(
                                () => {
                                    resolve();
                                }
                            );
                        } else if (response.body.status === 'AUTH_FAILED') {
                            reject(404);
                        } else {
                            reject(500);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

}
