import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UrlManagerService {

    urlSubject = new BehaviorSubject<any>(undefined);

    constructor() {}

    updateUrl(url: string) {
        this.urlSubject.next(url);
    }

}
