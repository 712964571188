import {UrlManagerService} from './services/url.manager.service';
import {User} from './objects/user';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {UsersService} from './services/users.service';
import {Component, OnDestroy, OnInit} from '@angular/core';

import {AlertController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    user: User;
    url = '';

    private subs: Subscription;
    private subsUrl: Subscription;
    private subsObjectStatus: Subscription;
    private subsLogout: Subscription;

    newsCount = 0;
    columnsCount = 0;
    textsCount = 0;

    statusWorker = null;

    menuHided = false;

    constructor(
        private alertCtrl: AlertController,
        private urlManagerService: UrlManagerService,
        private router: Router,
        private authService: AuthService,
        private usersService: UsersService,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    onLogout() {
        this.alertCtrl.create({
            header: 'Авторизація',
            message: 'Ви дійсно бажаєте вийти?',
            buttons: ['Ні', {
                text: 'Так', handler: () => {
                    this.authService.logout();
                    this.router.navigateByUrl('/login');
                }
            }]
        }).then(alertEl => alertEl.present());
    }

    ngOnInit() {
        this.subsLogout = this.authService.subjectLogout.subscribe(isLogout => {
            if (isLogout) {
                this.newsCount = 0;
                this.columnsCount = 0;
                this.textsCount = 0;

                if (this.statusWorker !== null) {
                    clearInterval(this.statusWorker);
                    this.statusWorker = null;
                }
            }
        });

        this.subs = this.usersService.userSubject.subscribe(response => {
            if (response !== undefined) {
                if (response.status === 200) {
                    this.user = response.data;

                    if (this.user.editor) {
                        this.usersService.findObjectStatus();

                        this.statusWorker = setInterval(() => {
                            this.usersService.findObjectStatus();
                        }, 60000);
                    }
                } else if (response.status === 404) {
                    this.authService.logout();
                    this.router.navigateByUrl('/login');
                } else {
                    this.alertCtrl.create({
                        cssClass: 'alert-error',
                        header: 'Отримання профілю',
                        message: 'Внутрішня помилка серверу. Спробуйте пізніше',
                        buttons: ['ОК']
                    }).then(alertEl => {
                        alertEl.present();
                    });
                }
            }
        });

        this.subsUrl = this.urlManagerService.urlSubject.subscribe(url => {
            if (url !== undefined) {
                this.url = url;
            }
        });

        if (this.user === undefined && this.authService.isLoggedIn()) {
            this.usersService.findUser();
        }

        this.subsObjectStatus = this.usersService.objectStatusSubject.subscribe(response => {
            if (response !== undefined) {
                if (response.status === 200) {
                    this.newsCount = response.data.newsCount;
                    this.columnsCount = response.data.columnsCount;
                    this.textsCount = response.data.textsCount;
                } else if (response.status === 404) {
                    this.authService.logout();
                    this.router.navigateByUrl('/login');
                } else {
                    this.alertCtrl.create({
                        cssClass: 'alert-error',
                        header: 'Отримання статусу',
                        message: 'Внутрішня помилка серверу. Спробуйте пізніше',
                        buttons: ['ОК']
                    }).then(alertEl => {
                        alertEl.present();
                    });
                }
            }
        });
    }

    navigate(newUrl: string) {
        let mess = null;

        if (this.url === 'create-text') {
            mess = 'Ви дійсно бажаєте покинути створення тексту?';
        } else if (this.url === 'update-text') {
            mess = 'Ви дійсно бажаєте покинути оновлення тексту?';
        } else if (this.url === 'create-column') {
            mess = 'Ви дійсно бажаєте покинути створення колонки?';
        } else if (this.url === 'update-column') {
            mess = 'Ви дійсно бажаєте покинути оновлення колонки?';
        } else if (this.url === 'update-ad') {
            mess = 'Ви дійсно бажаєте покинути оновлення реклами?';
        } else if (this.url === 'update-brand') {
          mess = 'Ви дійсно бажаєте покинути оновлення брекндування?';
        } else if (this.url === 'create-news') {
            mess = 'Ви дійсно бажаєте покинути створення новини?';
        } else if (this.url === 'update-news') {
            mess = 'Ви дійсно бажаєте покинути оновлення новини?';
        } else if (this.url === 'create-quote') {
            mess = 'Ви дійсно бажаєте покинути створення цитати?';
        } else if (this.url === 'update-quote') {
            mess = 'Ви дійсно бажаєте покинути оновлення цитати?';
        } else if (this.url === 'create-user') {
            mess = 'Ви дійсно бажаєте покинути створення користувача?';
        } else if (this.url === 'create-ad') {
            mess = 'Ви дійсно бажаєте покинути створення реклами?';
        } else if (this.url === 'create-brand') {
            mess = 'Ви дійсно бажаєте покинути створення брендування?';
        }

        if (mess) {
            this.alertCtrl.create({
                header: 'Перехід сторінки',
                message: mess,
                buttons: ['Ні', {
                    text: 'Так', handler: () => {
                        this.router.navigateByUrl(newUrl);
                    }
                }]
            }).then(alertEl => alertEl.present());

        } else {
            this.router.navigateByUrl(newUrl);
        }
    }

    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }

        if (this.subsUrl) {
            this.subsUrl.unsubscribe();
        }

        if (this.subsObjectStatus) {
            this.subsObjectStatus.unsubscribe();
        }

        if (this.subsLogout) {
            this.subsLogout.unsubscribe();
        }
    }

    hideShowMenu() {
        this.menuHided = !this.menuHided;
    }
}
