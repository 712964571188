export class Variables {

    // static siteUrl = 'https://nashkiev.ua';
    static siteUrl = 'https://nashkiev.ua';
    static url = 'https://api.nashkiev.ua';
    //static url = 'http://192.168.132.232:8080';
    // static url = 'http://localhost:8080';
    //'http://localhost:8080';
    //'https://api.nashkiev.ua';

}
