import { Storage } from '@ionic/storage';
import { Variables } from './../utils/variables';
import { User } from './../objects/user';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    userSubject = new BehaviorSubject<any>(undefined);
    userListSubject = new BehaviorSubject<any>(undefined);
    objectStatusSubject = new BehaviorSubject<any>(undefined);

    constructor(
        private http: HttpClient,
        private storage: Storage) {
    }

    findObjectStatus(): void {
        this.storage.get('cookie').then(data => {
            if (data) {
                const options = { headers: new HttpHeaders({ auth: data }) };

                this.http.get<any>(Variables.url + '/objects/object-status', options)
                .subscribe(response => {
                    if (response.status === 'OK') {
                        this.objectStatusSubject.next({ status: 200, data: response.data });

                    } else if (response.status === 'AUTH_FAILED') {
                        this.objectStatusSubject.next({ status: 404 });
                    } else {
                        this.objectStatusSubject.next({ status: 500 });
                    }
                },
                error => {
                    this.objectStatusSubject.next({ status: 500 });
                }
            );
            }
        });
    }

    findUser(): void {
        this.storage.get('cookie').then(data => {
            if (data) {
                const options = { headers: new HttpHeaders({ auth: data }) };

                this.http.get<any>(Variables.url + '/users', options)
                .subscribe(response => {
                    if (response.status === 'OK') {
                        this.userSubject.next({ status: 200, data: response.data });

                    } else if (response.status === 'AUTH_FAILED') {
                        this.userSubject.next({ status: 404 });
                    } else {
                        this.userSubject.next({ status: 500 });
                    }
                },
                error => {
                    this.userSubject.next({ status: 500 });
                }
            );
            }
        });
    }

    findUsers(filter: string, limit: number, offset: number, excludeIds: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.storage.get('cookie').then(data => {
                if (data) {
                    const options = { headers: new HttpHeaders({ auth: data }) };

                    let excludeParam = '';
                    if (excludeIds && excludeIds.length > 0) {
                        excludeIds.forEach((item) => {
                            excludeParam += '&excludeIds=' + item;
                        });
                    }

                    this.http.get<any>(Variables.url + '/users/find?filter=' + encodeURIComponent(filter) +
                    '&limit=' + limit + '&offset=' + offset + excludeParam,
                    options)
                    .subscribe(response => {
                        if (response.status === 'OK') {
                            resolve(response.data);

                        } else if (response.status === 'AUTH_FAILED') {
                            reject(404);
                        } else {
                            reject(500);
                        }
                    },
                    error => {
                        reject(500);
                    });
                }
            });
        });
    }

    find(limit: number, offset: number, filter: string): void {
        this.storage.get('cookie').then(data => {
            if (data) {
                const options = { headers: new HttpHeaders({ auth: data }) };

                this.http.get<any>(Variables.url + '/users/find-all?limit=' + limit + '&offset=' + offset +
                (filter ? '&filter=' + filter : ''), options)
                    .subscribe(response => {
                        if (response.status === 'OK') {
                            this.userListSubject.next({ status: 200, data: response.data });

                        } else if (response.status === 'AUTH_FAILED') {
                            this.userListSubject.next({ status: 404 });
                        } else {
                            this.userListSubject.next({ status: 500 });
                        }
                    },
                    error => {
                        this.userListSubject.next({ status: 500 });
                    }
                );
            }
        });
    }

    removeUser(id: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.storage.get('cookie').then(data => {
                if (data) {
                    const options = { headers: new HttpHeaders({ auth: data }) };

                    this.http.post<any>(Variables.url + '/users/remove?id=' + id, null,
                    options)
                    .subscribe(response => {
                        if (response.status === 'OK') {
                            resolve();

                        } else if (response.status === 'AUTH_FAILED') {
                            reject(404);
                        } else {
                            reject(500);
                        }
                    },
                    error => {
                        reject(500);
                    });
                }
            });
        });
    }

    saveUser(user: User): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.storage.get('cookie').then(data => {
                if (data) {
                    const options = { headers: new HttpHeaders({ auth: data }) };

                    this.http.post<any>(Variables.url + '/users/save', user,
                    options)
                    .subscribe(response => {
                        if (response.status === 'OK') {
                            resolve(response.data);

                        } else if (response.status === 'AUTH_FAILED') {
                            reject(404);
                        } else if (response.status === 'UNSUPPORTED_OPERATION') {
                            reject(405);
                        } else {
                            reject(500);
                        }
                    },
                    error => {
                        reject(500);
                    });
                }
            });
        });
    }

    updateUserByAdmin(user: User): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.storage.get('cookie').then(data => {
                if (data) {
                    const options = { headers: new HttpHeaders({ auth: data }) };

                    this.http.post<any>(Variables.url + '/users/admin-update', user,
                    options)
                    .subscribe(response => {
                        if (response.status === 'OK') {
                            resolve(response.data);

                        } else if (response.status === 'AUTH_FAILED') {
                            reject(404);
                        } else {
                            reject(500);
                        }
                    },
                    error => {
                        reject(500);
                    });
                }
            });
        });
    }

    restorePassword(id: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.storage.get('cookie').then(data => {
                if (data) {
                    const options = { headers: new HttpHeaders({ auth: data }) };

                    this.http.post<any>(Variables.url + '/users/restore-password?id=' + id, null,
                    options)
                    .subscribe(response => {
                        if (response.status === 'OK') {
                            resolve(response.data);

                        } else if (response.status === 'AUTH_FAILED') {
                            reject(404);
                        } else {
                            reject(500);
                        }
                    },
                    error => {
                        reject(500);
                    });
                }
            });
        });
    }

    updateUser(user: User): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.storage.get('cookie').then(data => {
                if (data) {
                    const options = { headers: new HttpHeaders({ auth: data }) };

                    this.http.put<any>(Variables.url + '/users', user,
                    options)
                    .subscribe(response => {
                        if (response.status === 'OK') {
                            resolve(response.data);

                        } else if (response.status === 'AUTH_FAILED') {
                            reject(404);
                        } else if (response.status === 'UNSUPPORTED_OPERATION') {
                            reject(405);
                        } else {
                            reject(500);
                        }
                    },
                    error => {
                        reject(500);
                    });
                }
            });
        });
    }

    findUserById(id: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.storage.get('cookie').then(data => {
                if (data) {
                    const options = { headers: new HttpHeaders({ auth: data }) };

                    this.http.get<any>(Variables.url + '/users/find-by-id?id=' + id,
                    options)
                    .subscribe(response => {
                        if (response.status === 'OK') {
                            resolve(response.data);

                        } else if (response.status === 'AUTH_FAILED') {
                            reject(404);
                        } else {
                            reject(500);
                        }
                    },
                    error => {
                        reject(500);
                    });
                }
            });
        });
    }

}
